export const appRoutes = {
  root: '/',
  dashboard: '/dashboard',
  myPlan: (year) => `/my-plan/${year}`,
  planCalendar: (calendarId) => `/plan-calendar/${calendarId}`,
  holiday: (year) => `/holiday/${year}`,
  addHoliday: (year) => `/holiday/${year}/add-holiday`,
  sessionList: `/plan-session-list`,
  createNotice: `/create-notice`,
  submissionStat: (year) => `/plan-submission-stat/${year}`,
  submissionDetails: (selectedYear, zoneId) =>
    `/plan-submission-details/${selectedYear}?zoneId=${zoneId}`,
  isasComplaintDetails: (selectedYear, zoneId) =>
    `/isas-complaint-details/zone/${zoneId}/year/${selectedYear}`,
  addVisitPlanSession: '/add-session-plan',
  sessionPlan: (id) => `/session-plan/${id}`,
  updateSessionPlan: (id) => `/update-session-plan/${id}`,
  addVisitPlan: (sessionId) => `/plan-session/${sessionId}/add-visit-plan`,
  revisedPlan: (zoneId, year) => `/plan-to-be-revised/zone/${zoneId}/year/${year}`,
  noticeDetails: (id) => `/notice-details/${id}`,
  UserNoticeDetails: (id) => `/user-notice-details/${id}`,
  noticeManagement: '/notice-management',
  noticeList: '/notice-list',
  createNoticeSuccess: '/notice-creation/succeed',
  updateNoticeSuccess: '/notice-updation/succeed',
  deleteNoticeSuccess: '/delete-notice/success',
  createNoticeFail: '/notice-creation/failed',
  updateNoticeFail: '/notice-updation/failed',
  updateNotice: (id) => `/update-notice/${id}`,
  curriculumMonitoring: 'monitoring/1',
  officerList: '/officer-list',
  monitoringBI: '/monitoring-bi',
  officerDetails: (officerId) => `/officer-details/${officerId}`,
  instituteList: '/institute-list',
  instituteDetails: (eiin) => `/institutes/${eiin}`,
  auditLog: '/audit-log',
  institutionReport: '/report/institution',
  officeReport: '/report/office',
  curriculumReport: '/report/curriculum',
  generalVisits: '/general-visits',
  generalVisitDetails: (visitId) => `/general-visits/${visitId}`,
  curriculumVisits: '/curriculum-visits',
  curriculumVisitDetails: (visitId) => `/curriculum-visits/${visitId}`,
  officeVisits: '/office-visits',
  officeVisitDetails: (visitId) => `/office-visits/${visitId}`,
  monitoringSubjectiveReport: (type) => `/monitoring-subjective-report/${type}`,
  othersReport: '/report/others',
  planSubmissionReport: '/report/others/plan-submission',
  officeInstituteSummaryReport: '/report/others/office-institute-summary-report',
  monitoringPlanReport: '/report/institution/monitoring-plan',
  monitoringActivitiesCompletedReport: '/report/institution/monitoring-activities-completed',
  monitoringActivitiesCompletedDelayReport:
    '/report/institution/monitoring-activities-completed-delay',
  monitoringActivitiesIncompleteReport: '/report/institution/monitoring-activities-incomplete',
  auditFailed: '/audit/failed',
  settings: '/settings',
  helpCenter: '/help-center',
  settingsSuccess: '/settings/succeed',
  settingsfail: '/settings/failed',
  monitoringPlanNotFound: '/officers-monitoring-plan/not-found',
  surveyFormError: '/survey-form-fetching/failed',
  monitoringSurveyBI: (type) => `/survey-monitoring-bi/${type}`,
  instituteMonitoringAbsentReport: '/report/institution/monitoring-teacher-staff-absent-count',
  officeMonitoringAbsentReport: '/report/office/monitoring-officer-staff-absent-count',
  officeMonitoringSummaryReport: '/report/office/monitoring-summary',
  redirectToSSO: 'login/redirect-to-sso',
};
