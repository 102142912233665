import axios from 'axios';
import { logout } from '../services/logoutService';
import { apiRoutes } from '../constants/apiRoutes';
import { successfulLogin } from '../pages/auth/authSlice';
import { updateHomePageMessage } from '../redux/generalSlice';

const HttpInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const formDataMultipart = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

HttpInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['x-dms-lang'] = store.getState().general.appLanguage;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

HttpInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRqst = error.config;
    let commonError = {
      statusCode: 503,
      message: 'Server is unavailable',
      code: 9999,
    };
    if (
      error.response &&
      error.response.status.toString() === process.env.REACT_APP_TOKEN_EXPIRATION_CODE.toString()
    ) {
      return HttpInstance.get(apiRoutes.getRefreshToken)
        .then((res) => {
          const { expiresAt, token, refreshBefore, user } = res.data;
          store.dispatch(successfulLogin({ expiresAt, token, refreshBefore, user }));
          return HttpInstance(originalRqst);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            store.dispatch(logout());
            store.dispatch(updateHomePageMessage('দয়া করে পুনরায় লগিন করুন'));
          }

          commonError = {
            ...commonError,
            statusCode: e.response?.status,
            code: e.response.data?.code,
            message: e.response?.data?.message,
          };

          return Promise.reject(e);
        });
    }
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
      store.dispatch(updateHomePageMessage('দয়া করে পুনরায় লগিন করুন'));
    }

    if (error.response) {
      commonError = {
        ...commonError,
        statusCode: error.response.status,
        code: error.response.data.code ?? 9999,
        message: error.response.data.message ?? error.response.data,
      };
    }
    return Promise.reject(commonError);
  },
);

export default HttpInstance;
