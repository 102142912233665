import React, { useEffect, useRef } from 'react';
import MultiSelect from 'react-select';

const customStyles = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#FECBA1',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#212529',
  }),
  //   multiValueRemove: (provided) => ({
  //     ...provided,
  //     color: 'black',
  //     ':hover': {
  //       backgroundColor: 'black',
  //       color: 'white',
  //     },
  //   }),
};

const AppMultiSelect = ({
  placeholder,
  disabled = false,
  options,
  onChange,
  value,
  AllSelectLabel = 'All',
  noOptionsMessage = () => {
    /* */
  },
}) => {
  const valueRef = useRef(value);
  valueRef.current = value;

  const selectAllOption = {
    value: 'SELECT_ALL',
    label: AllSelectLabel,
  };

  const isSelectAllSelected = () => {
    if (options.length === 1) {
      return false;
    }
    if (!options.length) {
      return false;
    }
    return valueRef.current.length === options.length;
  };

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) || isSelectAllSelected();

  const getOptions = () => {
    if (options.length > 1) {
      return [selectAllOption, ...options];
    }
    return [...options];
  };

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : value);

  const handleChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      onChange(options, actionMeta);
    } else if (
      (action === 'deselect-option' && option.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue.value === selectAllOption.value)
    ) {
      onChange([], actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      onChange(
        options.filter(({ value }) => value !== option.value),
        actionMeta,
      );
    } else {
      onChange(newValue || [], actionMeta);
    }
  };

  useEffect(() => {
    if (options.length === 1 && value.length === 0) {
      onChange(options, { action: 'select-option' });
    }
  }, [options, onChange, value]);

  return (
    <MultiSelect
      isMulti
      value={getValue()}
      isDisabled={disabled || options.length === 1}
      styles={customStyles}
      options={getOptions()}
      onChange={handleChange}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      isOptionSelected={isOptionSelected}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default AppMultiSelect;
