import React, { createContext, useContext, useCallback, useState } from 'react';

const TooltipContext = createContext(null);

export function TooltipContextProvider({ children }) {
  const [showTool, setShowTool] = useState(false);
  const [text, setText] = useState('');
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  return (
    <TooltipContext.Provider value={{ setShowTool, showTool, text, setText, x, setX, y, setY }}>
      {children}
    </TooltipContext.Provider>
  );
}

export function useTooltip() {
  const { setShowTool, showTool, text, x, setX, y, setY, setText } = useContext(TooltipContext);

  const showTooltip = useCallback(
    (x, y, text) => {
      setX(x);
      setY(y);
      setShowTool(true);
      setText(text);
    },
    [setX, setY, setShowTool, setText],
  );

  const hideTooltip = () => {
    setShowTool(false);
  };

  return {
    showTooltip,
    hideTooltip,
    isTooltipVisible: showTool,
    x,
    y,
    text,
  };
}
