import React from 'react';
import Button from '../pattern/Button';
import { resource_not_found_icon } from '../../assets/index.js';

const ResourceNotFound = ({
  onButtonClick,
  buttonText = 'Back',
  hideButton,
  titleText = 'Sorry',
  messageText = 'Information not found',
  contentHeight = '50vh',
}) => {
  return (
    <div className='error-page-container' style={{ height: contentHeight }}>
      <div>
        <img className='' src={resource_not_found_icon} />
      </div>
      <div className='d-flex flex-column align-items-center'>
        <h2>{titleText}</h2>
        <span>{messageText}</span>
      </div>
      {!hideButton && (
        <div>
          <Button className={'default-btn'} onClick={onButtonClick}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ResourceNotFound;
