import { createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../../constants/fetchStatus';
import { fetchUserNotification } from './notificationApi';

const initialState = {
  notifications: [],
  status: STATUS.IDLE,
};

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    resetNotification(state) {
      Object.assign(state, initialState);
    },
    updateNotification(state, action) {
      const { notificationId, userId, updatedObject } = action.payload;
      const index = state.notifications.findIndex(
        (item) => +item.notificationId === +notificationId && +item.userId === +userId,
      );

      if (index !== -1) {
        state.notifications[index] = { ...state.notifications[index], ...updatedObject };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserNotification.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchUserNotification.fulfilled, (state, action) => {
        const existingNotifications = state.notifications;
        const { response, userId } = action.payload;

        let results = state.notifications.filter(
          (ele) => ele.isVisible && new Date(ele.expiresAt) > new Date(),
        );

        response.forEach((ele) => {
          const isExist = existingNotifications.some(
            (notificationObj) =>
              notificationObj.notificationId.toString() === ele.notificationId.toString() &&
              notificationObj.userId.toString() === userId.toString(),
          );

          if (isExist) {
            const updatedNotification = {
              ...ele,
              isRead: false,
              userId,
            };
            results = results.map((e) => {
              if (e.notificationId === ele.notificationId && +e.userId === +userId)
                return updatedNotification;
              else return e;
            });
          } else {
            const newNotification = {
              ...ele,
              isRead: false,
              userId,
            };
            results.push(newNotification);
          }
        });

        state.notifications = results.sort(
          (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt),
        );
        state.status = STATUS.SUCCESS;
      })
      .addCase(fetchUserNotification.rejected, (state) => {
        state.status = STATUS.ERROR;
      });
  },
});

export const { resetNotification, addNotification, updateNotification, addIfNotExisit } =
  notificationSlice.actions;

//selector
export const getUserNotification = (state) => state.userNotification;

export default notificationSlice.reducer;
