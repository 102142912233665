import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { logout_icon, dropdown_icon_white, profile_photo, bell_icon } from '../../assets';
import { fetchUserNotification } from '../../pages/notice/notificationApi';
import { getUserNotification } from '../../pages/notice/NotificationSlice';
import { digitConversion, en2BnConversion } from '../../utils/helperFunction';
import { generalReducer } from '../../redux/generalSlice';
import { appRoutes } from '../../constants/appRoutes';
import { logout } from '../../services/logoutService';
import { authInfo } from '../../pages/auth/authSlice';

const NavProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user: { userId, fullNameBangla, fullNameEnglish, designationBangla, designationEnglish, activeJurisdiction:{ workstationNameEnglish, workstationNameBangla} },
  } = useSelector(authInfo);

  const { appLanguage } = useSelector(generalReducer);

  const unreadCount = useSelector(getUserNotification)
    .notifications.filter((n) => n.userId === userId)
    .reduce((count, notification) => {
      if (notification.isVisible && !notification.isRead) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);

  useEffect(() => {
    dispatch(fetchUserNotification());

    const FETCH_INTERVAL_MIN = process.env.REACT_APP_NOTICE_FETCH_INTERVAL_MIN || 10;
    const intervalId = setInterval(
      () => dispatch(fetchUserNotification()),
      parseInt(FETCH_INTERVAL_MIN) * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div className='d-flex align-items-center'>
      <>
        <div className='d-flex align-items-center'>
          <div className='notification-div'>
            <img
              src={bell_icon}
              className='nav-bell-icon img-fluid pointer'
              alt='bell-icon'
              onClick={() => {
                navigate(appRoutes.noticeList);
              }}
            />

            {unreadCount > 0 && (
              <div className='notification-badge'>{digitConversion(unreadCount, appLanguage)}</div>
            )}
          </div>
        </div>
        <Dropdown>
          <Dropdown.Toggle>
              <div className='d-flex align-items-center gap-3'>
                <img
                  src={profile_photo}
                  className='nav-profile-img rounded-circle img-fluid '
                  alt='Profile picture'
                />
                <div className='d-flex flex-column'>
                  <div className='name-text'>
                    {en2BnConversion(fullNameBangla, fullNameEnglish, appLanguage)}, &nbsp;
                    <span className='designation-text'>
                    {en2BnConversion(designationBangla, designationEnglish, appLanguage)}
                    </span>
                  </div>
                  <div className='designation-text'>     
                    {en2BnConversion(workstationNameBangla, workstationNameEnglish, appLanguage)}                    
                  </div>
                </div>{' '}
                <img src={dropdown_icon_white} className=' img-fluid ' alt='Profile picture' />
              </div>
            </Dropdown.Toggle>
          <Dropdown.Menu className='mt-3'>
            <Dropdown.Item
              onClick={() => {
                dispatch(logout());
              }}
            >
              <img src={logout_icon} />
              <span className='pt-1'>{t('topbar.logout')} </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    </div>
  );
};

export default NavProfile;
