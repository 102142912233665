import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { router } from './routes';
import { generalReducer } from './redux/generalSlice';

function App() {
  const { i18n } = useTranslation();
  const { appLanguage } = useSelector(generalReducer);

  useEffect(() => {
    i18n.changeLanguage(appLanguage);
  }, [i18n, appLanguage]);

  return <RouterProvider router={router} />;
}

export default App;
