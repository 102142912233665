import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const AppToast = ({ show, onClose, message, delay = 3000, variant, header, position }) => {
  return (
    <ToastContainer position={position} className='m-1 position-fixed'>
      <Toast onClose={onClose} show={show} delay={delay} autohide bg={variant}>
        <Toast.Header className='text-center'>
          <strong className='me-auto'>{header}</strong>
        </Toast.Header>
        <Toast.Body className='text-white'>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default AppToast;
