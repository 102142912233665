import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { appLanguages, localizationNS } from '../constants/appConstants';

i18n.use(initReactI18next).init({
  fallbackLng: appLanguages.English,
  lng: appLanguages.English,
  resources: {
    en: {
      common: require('./locales/en/common.json'),
      visit: require('./locales/en/visit.json'),
      notice: require('./locales/en/notice.json'),
      cliche: require('./locales/en/cliche.json'),
      monitoring: require('./locales/en/monitoring.json'),
      report: require('./locales/en/report.json'),
      settings: require('./locales/en/settings.json'),
    },
    bn: {
      common: require('./locales/bn/common.json'),
      visit: require('./locales/bn/visit.json'),
      notice: require('./locales/bn/notice.json'),
      cliche: require('./locales/bn/cliche.json'),
      monitoring: require('./locales/bn/monitoring.json'),
      report: require('./locales/bn/report.json'),
      settings: require('./locales/bn/settings.json'),
    },
  },
  ns: [
    localizationNS.common,
    localizationNS.visit,
    localizationNS.notice,
    localizationNS.cliche,
    localizationNS.monitoring,
    localizationNS.report,
    localizationNS.settings,
  ],
  defaultNS: localizationNS.common,
});

i18n.languages = [appLanguages.English, appLanguages.Bengali];

export default i18n;
