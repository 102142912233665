import React from 'react';
import { useTranslation } from 'react-i18next';
import { localizationNS } from '../../constants/appConstants';

const NotFound = () => {
  const tCommon = useTranslation(localizationNS.common).t;

  return (
    <div className='text-center p-5 opacity-50'>
      <h5 className='not-found center'>{tCommon('notFound')}</h5>
    </div>
  );
};

export default NotFound;
