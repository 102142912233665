import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dms_logo from '../../../src/assets/icons/gov_bd_logo.svg';
import { appRoutes } from '../../constants/appRoutes';
import { hamburger_menu, cross_white_icon } from '../../assets';
import NavProfile from './NavProfile';
import { authInfo } from '../../pages/auth/authSlice';
import Button from '../pattern/Button';
import '../index.css';

const Topbar = ({ handleClick, showMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoggedIn } = useSelector(authInfo);

  const onLoginButton = () => {
    navigate(appRoutes.redirectToSSO);
  };

  const handleLogoClick = () => {
    isLoggedIn ? navigate(appRoutes.dashboard) : navigate(appRoutes.root);
  };

  return (
    <div className='dms-primary-color sticky-top'>
      <div className='topbar-container'>
        <div className='fs-2 fw-bold d-flex align-items-center gap-2 pointer'>
          <span onClick={handleClick}>
            <img
              src={hamburger_menu}
              className={showMenu ? 'd-none' : ' d-lg-none d-inline-block'}
            />
            <img
              src={cross_white_icon}
              className={showMenu ? 'd-lg-none d-inline-block' : 'd-none'}
            />
          </span>
          <div className='d-flex align-items-center gap-2 ' onClick={handleLogoClick}>
            <img src={dms_logo} className='img-fluid' alt='DMS logo' />
            <span className='logo-text'>DMS</span>
            <span className='logo-small-text'>
              Digital Monitoring <br /> System
            </span>
          </div>
        </div>
        {isLoggedIn ? <NavProfile /> : <Button onClick={onLoginButton}>{t('login')}</Button>}
      </div>
    </div>
  );
};

export default Topbar;
