import HttpInstance from '../../configs/axiosClient';
import { apiRoutes } from '../../constants/apiRoutes';
import { instituteApiRoutes } from '../../constants/apiRoutes/instituteApiRoutes';
import { prepareDownloadFile, reportDownloadConfig } from '../../utils/helperFunction';

export const getZones = () => {
  return HttpInstance.get(apiRoutes.getZones);
};

export const getAllLocations = () => {
  return HttpInstance.get(apiRoutes.getAllLocations);
};

export const getInstitutionsByPathParams = (path, params) => {
  return HttpInstance.get(apiRoutes.getIntitutionsByPathParams(path, params));
};

export const getAllSession = () => {
  return HttpInstance.get(apiRoutes.getAllSession);
};

export const getLastYear = () => {
  return HttpInstance.get(apiRoutes.getSessionLastYear);
};

export const initiatePlan = (data) => {
  return HttpInstance.post(apiRoutes.initiateNewPlan, data);
};

export const addNewPlan = (data, sessionId) => {
  return HttpInstance.post(apiRoutes.addNewPlan(sessionId), data);
};

export const getHolidays = (year) => {
  return HttpInstance.get(apiRoutes.getHolidays(year));
};

export const addHolidays = (data) => {
  return HttpInstance.post(apiRoutes.addHolidays, data);
};

export const deleteHoliday = (id) => {
  return HttpInstance.post(apiRoutes.deleteHoliday(id));
};

export const getSelectedDateAllPlanByPlanIdAndOfficerId = (officerId, planId, date) => {
  return HttpInstance.get(apiRoutes.getAllPlanByPlanIdAndDate(officerId, planId, date));
};

export const getAInstituteAllPlanForASesison = (sessionId, instituteId) => {
  return HttpInstance.get(apiRoutes.getAInstituteAllPlan(sessionId, instituteId));
};

export const getNonCompliantInstitutes = (
  zone = '',
  district = '',
  upazila = '',
  category = '',
  year = new Date().getFullYear(),
) => {
  return HttpInstance.get(
    instituteApiRoutes.getNonCompliantInstitutes(zone, district, upazila, category, year),
  );
};

export const deleteInstituteVisitPlanDetail = (id) => {
  return HttpInstance.delete(apiRoutes.deleteVisitPlanDetail(id));
};

export const updateVisitPlanSession = (id, data) => {
  return HttpInstance.post(apiRoutes.updateSessionPlan(id), data);
};

export const submitVisitPlan = (officerId, planYear) => {
  return HttpInstance.post(apiRoutes.submitVisitPlan(officerId, planYear));
};

export const getVisitPlanSessionById = (id) => {
  return HttpInstance.get(apiRoutes.getSessionById(id));
};

export const getOfficerPlan = (
  officerId,
  planSessionId = '',
  planYear = '',
  visitDate = '',
  fromDate = '',
  toDate = '',
  instituteId = '',
) => {
  return HttpInstance.get(
    apiRoutes.getOfficerAllPlan(
      officerId,
      planSessionId,
      planYear,
      visitDate,
      fromDate,
      toDate,
      instituteId,
    ),
  );
};

export const getOfficerInstituteCount = () => {
  return HttpInstance.get(apiRoutes.getOfficerInstitute);
};

export const getAllZoneStateByYear = (year) => {
  return HttpInstance.get(apiRoutes.getAllZoneStatByYear(year));
};

export const getZoneStat = (year, zoneId, districtId, upazilaId, designationId, status) => {
  return HttpInstance.get(
    apiRoutes.getZoneStat(year, zoneId, districtId, upazilaId, designationId, status),
  );
};

export const getPlanSubmission = (
  year,
  size,
  pageNumber,
  zoneId,
  districtId,
  upazilaId,
  status,
  designationId,
) => {
  return HttpInstance.get(
    apiRoutes.getPlanSubmission(
      year,
      size,
      pageNumber,
      zoneId,
      districtId,
      upazilaId,
      status,
      designationId,
    ),
  );
};

export const getAllDesignations = (userType) => {
  return HttpInstance.get(apiRoutes.getAllDesignations(userType));
};

export const getVisitCalendarById = (planCalendarId) => {
  return HttpInstance.get(apiRoutes.getInstitutePlanCalendar(planCalendarId));
};

export const getOfficerVisitPlanByYear = (planYear, officerId) => {
  return HttpInstance.get(apiRoutes.getOfficerVisitPlanByYear(planYear, officerId));
};

export const getNonCompliantVisitPlan = (params) => {
  return HttpInstance.get(apiRoutes.getNonCompliantVisitPlan(params));
};

export const getIsasComplaintDetails = (pageNumber, pageSize, planYear, zoneId, districtId) => {
  return HttpInstance.get(
    apiRoutes.isasComplaintDetails(pageNumber, pageSize, planYear, zoneId, districtId),
  );
};

export const sendOfficersForRevision = (officersId, planYear, comment) => {
  return HttpInstance.post(apiRoutes.sendForRevision, { officersId, planYear, comment });
};

export const sendReminderToOfficers = (officersId) => {
  return HttpInstance.post(apiRoutes.sendReminder, officersId);
};

export const downloadPlanSubmissionDetails = async (type, location, params) => {
  const response = await HttpInstance.get(
    apiRoutes.downloadPlanSubmissionDetails(type, location, params),
    reportDownloadConfig,
  );
  const fileName = `officer_plan_submission`;
  prepareDownloadFile(response.data, fileName, type);
};

export const downloadOfficerVisitPlan = async (type, officerId, planYear) => {
  const response = await HttpInstance.get(
    apiRoutes.downloadOfficerVisitPlan(type, officerId, planYear),
    reportDownloadConfig,
  );
  const fileName = `officer_yearly_visit_plan_${officerId}_${planYear}`;
  prepareDownloadFile(response.data, fileName, type);
};
