import React from 'react';

const AppSpinner = ({ className = 'backdrop--loader' }) => {
  return (
    <div className={className}>
      <span className='loader'></span>
    </div>
  );
};

export default AppSpinner;
