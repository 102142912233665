import React from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { calendar_disabled, calendar_icon } from '../../assets';
import { en2BnConversion, getDefaultFormatDateFromTimestamp } from '../../utils/helperFunction';

const AppDatePicker = ({
  value,
  className,
  onChange,
  locale,
  disabled,
  minDate,
  maxDate,
  ...rest
}) => {
  const getDayPlaceholder = () => {
    return en2BnConversion('দিন', 'dd', locale);
  };
  const getMonthPlaceholder = () => {
    return en2BnConversion('মাস', 'mm', locale);
  };
  const getYearPlaceholder = () => {
    return en2BnConversion('বছর', 'yyyy', locale);
  };

  const handleChange = (date) => {
    const newDate = getDefaultFormatDateFromTimestamp(date);
    onChange(newDate);
  };

  return (
    <DatePicker
      calendarIcon={<img src={disabled ? calendar_disabled : calendar_icon} height={24} />}
      className={`react-date-picker_input ${className}`}
      value={value}
      locale={locale}
      clearIcon={null}
      format='dd-MM-y'
      onChange={handleChange}
      dayPlaceholder={getDayPlaceholder()}
      monthPlaceholder={getMonthPlaceholder()}
      yearPlaceholder={getYearPlaceholder()}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      {...rest}
    />
  );
};

export default AppDatePicker;
