import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpInstance from '../../configs/axiosClient';
import { noticeApiRoutes } from '../../constants/apiRoutes/noticeApiRoutes';
import { addDay, formatDateToNoticePattern } from '../../utils/dateTime';

export const fetchUserNotification = createAsyncThunk(
  'notificationSlice/fetchUserNotification',
  async (_, thunkApi) => {
    const state = thunkApi.getState();

    const userId = state.auth.user.userId;
    const notifications = state.userNotification.notifications.filter((n) => n.userId === userId);

    const response = await HttpInstance.get(
      noticeApiRoutes.userNoticeList(getFetchTime(notifications)),
    );

    return { response: response.data, userId: state.auth.user.userId };
  },
);

const getFetchTime = (userNotifications) => {
  if (userNotifications.length) {
    const latestModifiedAt = userNotifications.reduce((latest, notification) => {
      const notificationTime = new Date(notification.modifiedAt).getTime();
      return Math.max(latest, notificationTime);
    }, 0);
    return formatDateToNoticePattern(latestModifiedAt);
  } else {
    const INITIAL_FETCH_WINDOW_DAY = process.env.REACT_APP_NOTICE_INITIAL_FETCH_WINDOW_DAY || 30;
    return formatDateToNoticePattern(addDay(new Date(), -INITIAL_FETCH_WINDOW_DAY));
  }
};
