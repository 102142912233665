export const noticeApiRoutes = {
  getById: (id) => `/api/notifier/notification/${id}`,
  publish: `/api/notifier/create/notification`,
  update: (id) => `/api/notifier/update/${id}`,
  adminList: (pageNumber, pageSize) =>
    `/api/notifier/admin/notification?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  statusUpdate: '/api/notifier/notification/status/update',
  details: (id) => `/api/notifier/get/notification/${id}`,
  userNoticeList: (dateTime) => `/api/notifier/get/notifications?time=${dateTime}`,
};
