import React from 'react';

const InputField = ({
  className,
  value,
  placeholder,
  type,
  error,
  errorMessage,
  onChange,
  ...rest
}) => {
  return (
    <>
      <input
        className={`form-control ${className}`}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      {error && <div className='text-danger my-1 font-12'>{errorMessage}</div>}
    </>
  );
};

export default InputField;
